<template>
  <div class="hader">
    <div class="title">朵唯智慧水务管理平台</div>
    <div class="user-box">
      <el-dropdown @command="command">
        <span class="el-dropdown-link">
          {{userName}},欢迎您!
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="mod">修改密码</el-dropdown-item>
            <el-dropdown-item command="out">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <!-- 修改密码 -->
    <el-dialog custom-class="dialogFrom" width="500px" title="密码修改" v-model="modPassword">
      <el-form :model="modFrom" ref="ruleForm" :rules="rules">
        <!-- <el-form-item prop="password" class="long" label="当前密码" label-width="80px">
          <el-input type="password" v-model="modFrom.password" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item prop="newPassword" class="long" label="新密码" label-width="80px">
          <el-input type="password" v-model="modFrom.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="confirm" class="long" label="确认密码" label-width="80px">
          <el-input type="password" v-model="modFrom.confirm" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="modPassword = false">取 消</el-button>
        <el-button type="primary" @click="submitForm(ruleForm)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { removeToken } from "@/request/cookie";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { encrypt } from "@/request/crypto.js";
import request from "@/request/request.js";
import store from "@/store/index.js";
const router = useRouter();
let modPassword = ref(false),
  ruleForm = ref(null),
  userName = ref(""),
  modFrom = reactive({
    password: "",
    newPassword: "",
    confirm: "",
  }),
  rules = reactive({
    password: [{ required: true, message: "请输入当前密码" }],
    newPassword: [{ required: true, message: "请输入新密码" }],
    confirm: [{ required: true, message: "请确认密码" }],
  });
onMounted(() => {
  userName.value = store.getters.getUserName.showName;
});
async function submitForm(formEl) {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      if (modFrom.newPassword === modFrom.confirm) {
        request({
          method: "post",
          url: "admin/sysUser/modifyPasswd",
          data: {
            id: store.getters.getUserName.id,
            passwd: encrypt(modFrom.newPassword),
          },
        }).then(() => {
          modPassword.value = false;
          ElMessage({
            type: "success",
            message: "密码修改成功,请重新登录!",
          });
          out();
        });
      } else {
        ElMessage({
          type: "warning",
          message: "两次密码不一致!",
        });
      }
    } else {
      console.log("error submit!!");
      return false;
    }
  });
}
function command(str) {
  if (str === "mod") {
    modPassword.value = true;
  } else if (str === "out") {
    out();
  }
}
function out() {
  removeToken();
  router.push({
    name: "Login",
  });
}
</script>

<style lang="less" scoped>
.hader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  background-image: url("../../assets/img/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  .title {
    font-size: 24px;
    font-weight: 500;
  }
  .user-box {
    font-size: 14px;
    .el-dropdown {
      cursor: pointer;
      color: #409eff;
    }
  }
}
</style>