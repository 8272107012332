<template>
  <div class="content">
    <Header />
    <div :style="{height:indexObj.contentHeight + 'px'}" class="content-box">
      <Nav :nameValue="editableTabsValue" :height="indexObj.contentHeight" :collapse-flag="indexObj.collapseFlag" @handleNavName="handleNavName" />
      <div class="iframe">
        <div :style="{width:indexObj.tabsWidth+'px'}" ref="tabs" class="tabs">
          <el-tabs v-model="editableTabsValue" type="border-card" closable @tab-click="clickTab" @tab-remove="removeTab">
            <el-tab-pane v-for="(item) in indexObj.editableTabs" :key="item.name" :label="item.title" :name="item.name">
            </el-tab-pane>
          </el-tabs>
          <div class="nav-btn" @click="navSwitch">
            <component :is="iconStr" style="width: 16px; height:16px;" :style="{transform:`rotate(${indexObj.collapseFlag ? -180 : 0}deg)`}" />
          </div>
        </div>
        <div class="view-box" :style="{height:viewHeight + 'px'}">
          <router-view v-slot="{ Component }">
            <div :style="{height:viewHeight + 'px'}">
              <component :is="Component"></component>
            </div>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Header from "./header.vue";
import Nav from "./nav.vue";
const router = useRouter();
const store = useStore();
let indexObj = reactive({
    editableTabs: [],
    tabsWidth: 0,
    collapseFlag: false,
    contentHeight: document.body.offsetHeight - 60,
  }),
  tableName = ref([]),
  editableTabsValue = ref(""),
  iconStr = ref("DArrowLeft"),
  initTabes = reactive({
    //初始化标签页
    title: "",
    name: "",
  }),
  viewHeight = ref(200);

onMounted(() => {
  nextTick(() => {
    viewHeight.value = document.body.offsetHeight - 100;
    let menuTree = store.getters.getMenuTree.children;
    if (menuTree.length == 0) {
      console.error("没有菜单权限,请先配置权限!");
      return;
    } else {
      let name = "";
      if (menuTree[0].children) {
        name = menuTree[0].children[0].name;
        initTabes.title = menuTree[0].children[0].meta.title;
        initTabes.name = menuTree[0].children[0].name;
      } else {
        name = menuTree[0].name;
        initTabes.title = menuTree[0].meta.title;
        initTabes.name = menuTree[0].name;
      }
      tableName.value.push(name);
      router.push({
        name,
      });
    }
    indexObj.editableTabs.push(initTabes);
    editableTabsValue.value = initTabes.name;
    store.dispatch("changeTableHeight", document.body.offsetHeight - 280);
    initTabsWidth();
    window.onresize = () => {
      initTabsWidth();
      indexObj.contentHeight = document.body.offsetHeight - 60;
      viewHeight.value = document.body.offsetHeight - 100;
      store.dispatch("changeTableHeight", document.body.offsetHeight - 280);
    };
  });
});
//点击菜单栏
function handleNavName(obj) {
  let flag = indexObj.editableTabs.some((item) => item.name == obj.name);
  if (!flag) {
    indexObj.editableTabs.push({
      title: obj.meta.title,
      name: obj.name,
    });
  }
  editableTabsValue.value = obj.name;
  tableName.value.push(obj.name);
  viewPush(obj.name);
}
//关闭菜单栏
function removeTab(name) {
  let index = "",
    routerName;
  indexObj.editableTabs.forEach((item, ind) => {
    if (item.name == name) index = ind;
  });
  if (indexObj.editableTabs.length == 1) {
    if (indexObj.editableTabs[0].name != initTabes.name) {
      if (initTabes.name == editableTabsValue.value) return;
      indexObj.editableTabs.length = 0;
      indexObj.editableTabs.push(initTabes);
      editableTabsValue.value = initTabes.name;
      viewPush(initTabes.name);
    } else {
      indexObj.editableTabs.splice(index, 1);
      indexObj.editableTabs.push(initTabes);
      if (initTabes.name == editableTabsValue.value) return;
      editableTabsValue.value = initTabes.name;
      viewPush(initTabes.name);
    }
    return;
  }
  tableName.value.splice(index, 1);
  indexObj.editableTabs.splice(index, 1);
  routerName = indexObj.editableTabs[0].name;
  if (routerName == editableTabsValue.value) return;
  editableTabsValue.value = routerName;
  viewPush(routerName);
}
//点击Nav切换
function clickTab({ index }) {
  let name = indexObj.editableTabs[index].name;
  editableTabsValue.value = name;
  viewPush(name);
}
//菜单栏切换
function navSwitch() {
  indexObj.collapseFlag = !indexObj.collapseFlag;
  initTabsWidth();
}
//菜单栏跳转
function viewPush(name) {
  router.push({
    name,
  });
}
//初始化侧边栏的宽度
function initTabsWidth() {
  indexObj.tabsWidth =
    document.body.offsetWidth - (indexObj.collapseFlag ? 64 : 140);
}
</script>
<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content-box {
  height: calc(100% - 80px);
  display: flex;
}
.iframe {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 140px);
  .tabs {
    position: relative;
    height: 40px;
    background: #e6e6e6;
    .nav-btn {
      position: absolute;
      left: 0;
      top: 0;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      background-color: #f5f7fa;
      svg {
        transition: all 0.3s;
        margin-bottom: -2px;
      }
    }
    & ::v-deep .el-tabs__content {
      display: none;
    }
    & ::v-deep .el-tabs__header {
      border: none;
    }
    & ::v-deep .el-tabs {
      padding-left: 40px;
      border: none;
    }
    & ::v-deep .el-tabs__nav-scroll {
      background: #e0e3ec;
    }
    & ::v-deep .el-tabs__nav-wrap {
      margin-bottom: 0;
    }
    //未选中
    & ::v-deep .el-tabs__item {
      margin: 0 4px !important;
      font-size: 13px;
      color: #000 !important;
      background: #fff;
      border-radius: 10px 10px 0 0;
      height: 34px;
      line-height: 34px;
      margin-top: 6px !important;
      .el-icon {
        display: none;
        position: absolute;
        bottom: 8px;
        height: 0.9em;
        width: 0.9em;
      }
    }
    & ::v-deep .el-tabs__item:hover {
      .el-icon {
        display: inline-block;
        svg {
          height: 0.9em;
          width: 0.9em;
        }
      }
    }
    //选中
    & ::v-deep .is-active {
      border-radius: 10px 10px 0 0;
      color: #fff !important;
      padding-right: 24px !important;
      background-color: #409eff !important;
      .el-icon {
        display: inline-block;
        svg {
          height: 0.9em;
          width: 0.9em;
        }
      }
    }
  }
  .view-box {
    overflow: hidden;
  }
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s;
}
</style>
