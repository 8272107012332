import axios from 'axios'
import {
  getToken,
  removeToken
} from './cookie'
import {
  ElMessage
} from "element-plus";
import router from "@/router/index.js"
//处理git参数
function getParme(obj) {
  let str = ''
  for (let i in obj) {
    if (obj[i] !== "" && obj[i] !== undefined) {
      let text = obj[i]
      if (typeof obj[i] == "string") text = obj[i].trim()
      str += i + '=' + text + '&'
    }
  }
  return str.slice(0, str.length - 1)
}
//设置默认的Content-Type
axios.defaults.headers['Content-Type'] = "application/json"
// 创建axios实例
const service = axios.create({
  baseURL: 'https://api.dooviot.cn', //线上
  // baseURL: 'http://127.0.0.1:19015/', // 测试
  // baseURL: 'http://39.98.70.63:8858/', // 测试
  timeout: 60000 // 请求超时时间
})

//请求拦截器 请求前所做得事情
service.interceptors.request.use((config) => {
  if (getToken()) {
    config.headers['X-Dooviot-Token'] = getToken()
  }
  //处理get参数
  if (config.method == "get" && config.data) {
    let str = getParme(config.data)
    config.url += "?" + str
    delete config.data
  }
  if (config.url == "admin/upload/uploadFirmware") {
    config.headers['Content-Type'] = "multipart/form-data"
  }
  return config;
}, (error) => {
  return Promise.reject(error)
})

//响应拦截器 数回传过滤
service.interceptors.response.use((res) => {
  if (res.status == 200) {
    if (res.data.code == 200) {
      return res.data
    } else if (res.data.code == 210) { //登录过期
      removeToken();
      router.push({
        name: "Login"
      })
    } else {
      // console.log(res)
      if (res.data.data == null) {
        ElMessage.error(res.data.message)
      } else {
        ElMessage.error(res.data.data)
      }

      throw new Error(res.request.responseURL);
    }
  }
}, (error) => {
  return Promise.reject(error)
})

export default service