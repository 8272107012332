<template>
  <div style="height:100%">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform">
        <KeepAlive>
          <component :is="Component"></component>
        </KeepAlive>
      </transition>
    </router-view>
  </div>
</template>