import {
  createApp
} from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementPlus from 'element-plus'
import "default-passive-events"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(router)

app.use(store)

app.use(ElementPlus, {
  name: "zh-cn",
  size: "small"
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
//导出组件
app.mount('#app')