import CryptoJS from 'crypto-js'

var key = CryptoJS.enc.Utf8.parse('dooviot2019@ice_')
var iv = CryptoJS.enc.Utf8.parse('dooviot2019@ice_')

// aes加密
export function encrypt(context) {
  var encrypted = ''
  // eslint-disable-next-line no-empty
  if (typeof (context) === 'string') {
  } else if (typeof (context) === 'object') {
    context = JSON.stringify(context)
  }
  var srcs = CryptoJS.enc.Utf8.parse(context)
  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}
// aes解密
export function decrypt(context) {
  var decrypt = CryptoJS.AES.decrypt(context, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}