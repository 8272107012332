import {
  createStore
} from "vuex";
import {
  addRouter
} from "@/router/index.js"
import router from "@/router/index.js"
import request from "@/request/request.js";
export default createStore({
  state: {
    tableHeight: 0,
    parentId: 1,
    menuTree: [],
    userInfo: {},
    tableName: [],
  },
  getters: {
    getTabNameArr(state) {
      return state.tableName
    },
    getMenuTree(state) {
      return state.menuTree
    },
    getUserName(state) {
      return state.userInfo
    }
  },
  mutations: {
    changeTableHeight(state, data) {
      state.tableHeight = data
    },
    setUserInfo(state, data) {
      //通过ID排序一次
      let arr = data.menuTree.sort((a, b) => a.id - b.id)
      arr.forEach(element => {
        if (element.children.length != 0) {
          element.children.sort((a, b) => a.id - b.id);
        }
      });
      addRouter(arr).then(res => {
        state.menuTree = res
        router.push({
          name: "Home"
        })
      })
      state.userInfo = data.userInfo;
    }
  },
  actions: {
    changeTableHeight(content, data) {
      content.commit('changeTableHeight', data)
    },
    getUserInfo(content) {
      request({
        method: "get",
        url: "admin/sysUser/info",
      }).then((res) => {
        content.commit("setUserInfo", res.data)
      })
    },
  },
  modules: {},
});