import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import store from "@/store/index.js";
import {
  getToken,
} from '@/request/cookie.js'
import Login from "@/views/login/login"
import Home from "@/views/home/home"
import View from "@/views/home/view"
// import Demo from "@/views/demo"
const routerHistory = createWebHashHistory()
const routes = [{
  path: '/',
  name: 'Login',
  component: Login
}, ]
const router = createRouter({
  history: routerHistory,
  routes
})
router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (to.name === "Login") {
      next()
    } else {
      if (store.getters.getMenuTree.length == 0) {
        store.dispatch("getUserInfo")
      } else {
        next()
      }
    }
  } else {
    if (to.name === "Login") {
      next()
    } else {
      next({
        name: "Login"
      })
    }
  }
})
//动态添加路由
export function addRouter(data) {
  return new Promise((resolve) => {
    let homeChildren = []
    for (let i of data) {
      let obj = {
          path: i.componentPath,
          name: i.name,
        },
        childrenArr = [];
      if (i.children.length == 0) {
        obj.component = () => import(`@/views/${i.componentPath}/${i.name}.vue`);
        obj.meta = {
          title: i.title,
          icon: i.icon,
          keepAlive: true
        }
      } else {
        for (let j of i.children) {
          let childrenObj = {};
          childrenObj = {
            path: j.componentPath,
            name: j.name,
            component: () => import(`@/views/${i.componentPath}/${j.name}.vue`),
            meta: {
              title: j.title,
              keepAlive: true
            }
          }
          childrenArr.push(childrenObj)
        }
        obj.component = View;
        obj.children = childrenArr
        obj.meta = {
          title: i.title,
          icon: i.icon
        }
      }
      if (obj.name != "waterHome") homeChildren.push(obj)
    }
    let addObj = {
      path: '/Home',
      name: "Home",
      component: Home,
      children: homeChildren
    }
    router.addRoute(addObj)
    resolve(addObj)
  })
}

export default router