<template>
  <div class="nav-box" :style="{width:!props.collapseFlag ? '140px' : '64px'}">
    <el-menu :style="{height:height+'px'}" mode="vertical" :collapse="props.collapseFlag" :default-active="activeName" @select="handleSelect" background-color="rgb(3, 24, 40)" unique-opened text-color="#fff">
      <template v-for="(item,index) in routes.arr" :key="index">
        <template v-if="item.children">
          <el-sub-menu :index="index+''">
            <template #title>
              <component :is="item.meta.icon" style="width: 16px; height:16px;" />
              <span>{{item.meta.title}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-for="(minItem,minIndex) in item.children" :key="minIndex" :index="minItem.name"> 
                {{minItem.meta.title}}
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item :index="item.name">
            <component :is="item.meta.icon" style="width:18px; height:18px;" />
            <span>{{item.meta.title}}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script setup>
import { onMounted, reactive, defineProps, defineEmits, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
let routes = reactive({
    arr: [],
  }),
  activeName = ref("");
const props = defineProps({
  collapseFlag: Boolean,
  height: Number,
  nameValue: String,
});
const emits = defineEmits(["handleNavName"]);
watch(
  () => props.nameValue,
  (newValue) => {
    activeName.value = newValue;
  }
);
onMounted(() => {
  let Navroutes = store.getters.getMenuTree.children;
  routes.arr = Navroutes;
  if (Navroutes.length != 0) {
    activeName.value = Navroutes[0].name;
    
  }
  activeName.value = props.nameValue;
});
function handleSelect(name) {
  let obj = {};
  for (let i of routes.arr) {
    if (i.children) {
      for (let j of i.children) {
        if (j.name == name) obj = j;
      }
    } else {
      if (i.name == name) obj = i;
    }
  }
  emits("handleNavName", obj);
}
</script>
<style lang="less" scoped>
.nav-box {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: rgb(3, 24, 40);

  .el-menu {
    border-right: none;
    // overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    .el-menu-item-group__title {
      display: none !important;
    }
  }

  ::v-deep .el-menu-item,
  ::v-deep .el-sub-menu__title {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    & > svg {
      margin-top: -4px;
    }
    & > span {
      margin-left: 6px;
    }
    margin-left: -14px;
  }
  ::v-deep .el-menu-item-group {
    ul {
      margin-left: -12px !important;
      margin-top:-20px !important;
    }
  }
}
</style>