<template>
  <div class="content">
    <div class="login-from">
      <h1>朵唯智慧水务管理平台</h1>
      <el-form :model="ruleForm" :rules="rules" ref="formRef" style="width:350px">
        <el-form-item prop="account">
          <el-input size="default" @keyup.enter="center" prefix-icon="UserFilled" v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item prop="passwd">
          <el-input size="default" show-password @keyup.enter="center" prefix-icon="Key" type="password" v-model="ruleForm.passwd"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width:100%" type="primary" @click="submitForm()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from "vue";
import request from "@/request/request.js";
import { encrypt } from "@/request/crypto.js";
import { setToken } from "@/request/cookie.js";
import { useStore } from "vuex";
const store = useStore();
let ruleForm = reactive({
    account: "",
    passwd: "",
  }),
  formRef = ref(null),
  rules = reactive({
    account: [{ required: true, message: "请输入账号" }],
    passwd: [{ required: true, message: "请输入密码" }],
  });
function submitForm() {
  formRef.value.validate((valid) => {
    if (valid) {
      request({
        method: "post",
        data: {
          encryptStr: encrypt(ruleForm),
        },
        url: "admin/sysUser/login",
      }).then((res) => {
        let token = res.data;
        setToken(token);
        store.dispatch("getUserInfo");
      });
    } else {
      console.log("error submit!!");
      return false;
    }
  });
}
function center() {
  submitForm();
}
</script>
<style lang="less" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/bg_login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  flex-direction: column;
}
.login-from {
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 10px;
  margin-top: -200px;
}
h1 {
  color: #fff;
}
</style>